import React, {useState} from "react"
import Layout from "../components/Layout" 
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const WorkPage = ({ pageContext: { list } }) => {

  const [breadcrumb, setBreadcrumb] = useState(list[0].url);
  const [img, setImg] = useState({});
  
  return (
    <>
      <Layout>

        <div className="work-wrapper">
          <div className="details">
            <div className="breadcrumb">{breadcrumb}</div>
          </div>
          <div className="preview-wrapper">
            <div className="preview">
              <img src={list[0].image} id="img"/>
            </div>
            <div className="works">
              {list.map((page, index) => {

                let image = getImage(page.image);
                return <a href={page.url} data-img={page.image} id={index} onMouseOver={ function() { setImg(image); setBreadcrumb(page.url); document.getElementById('img').src = page.image} }>{page.label}</a>
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
  
}

export default WorkPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
